import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    background-color: #fff !important;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
`;
export default Spinner;

export const InitialSpinner = styled.div`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: #007bff !important;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
`;
