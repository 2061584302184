import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the Blogs container
export const initialState: ContainerState = {
    requesting: false,
    success: false,
    error: null,
    userInfo: {},
    userID: {},
    logoutRequest: false,
    logoutSuccess: false,
    logoutSuccessMessage: '',
    uploadingImage: false,
    imageData: null,
    imageUploadError: null,
    showHide: '',
};

const userInfoSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        validateTokenRequest(state, action: any) {
            state.requesting = true;
            state.error = null;
            state.userInfo = {};
            state.success = false;
        },

        validateTokenSuccess(state, action: any) {
            // const deserializedResp = action.payload;
            // state.userInfo = deserializedResp.userInfo;
            state.requesting = false;
            state.error = null;
            state.success = true;
        },

        validateTokenFailure(state, action: any) {
            // const deserializedResp = action.payload;
            // const responseData = deserializedResp.msg;

            // state.userInfo = {};
            state.error = action.payload.msg;
            state.requesting = false;
            state.success = false;
        },

        fetchUserDetailRequest(state, action: any) {
            state.requesting = true;
            state.error = null;
            state.userInfo = {};
            state.userID = action.payload;
            state.success = false;
        },

        fetchUserDetailSuccess(state, action: any) {
            const deserializedResp = action.payload;
            state.userInfo = deserializedResp?.adminDetail;
            state.requesting = false;
            state.error = null;
            state.success = true;
        },

        fetchUserDetailFailure(state, action: any) {
            const deserializedResp = action.payload;
            const responseData = deserializedResp.msg;

            state.userInfo = {};
            state.error = responseData;
            state.requesting = false;
            state.success = false;
        },
        logoutRequest(state) {
            state.logoutRequest = true;
            state.error = null;
            state.logoutSuccessMessage = '';
            state.logoutSuccess = false;
        },
        logoutSuccess(state, action: any) {
            const deserializedResp = action.payload;
            const responseData = deserializedResp.responseLogout;
            localStorage.removeItem('token');

            state.logoutSuccessMessage = responseData;
            state.logoutRequest = false;
            state.error = null;
            state.userInfo = {};
            state.logoutSuccess = true;
        },
        logoutFailure(state, action: any) {
            const deserializedResp = action.payload.data;
            state.logoutSuccessMessage = '';
            state.error = deserializedResp;
            state.logoutRequest = false;
            state.logoutSuccess = false;
        },

        uploadImageRequest(state, action: PayloadAction<any>) {
            state.uploadingImage = true;
            state.imageUploadError = null;
            state.success = false;
        },
        uploadImageSuccess(state, action: PayloadAction<any>) {
            const response = action?.payload;
            state.imageData = response?.file_location || [];
            state.uploadingImage = false;
            state.imageUploadError = null;
        },
        uploadImageFailure(state, action: PayloadAction<any>) {
            const response = action.payload;
            state.uploadingImage = false;
            state.imageUploadError =
                response && (response.message || response.msg);
            state.imageData = null;
        },

        clearState(state) {
            state.requesting = false;
            state.error = null;
            state.userInfo = {};
            state.userID = {};
            state.success = false;
            state.logoutRequest = false;
            state.logoutSuccessMessage = '';
            state.logoutSuccess = false;
        },

        openSideBar(state, action: PayloadAction<any>) {
            state.showHide = action.payload;
        },

        closeSideBar(state) {
            state.showHide = '';
        },

        clearImageState(state) {
            state.imageData = null;
            state.imageUploadError = null;
        },
    },
});

export const { actions, reducer, name: sliceKey } = userInfoSlice;
