/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const App = lazyLoad(
    () => import('./index'),
    module => module.App,
);
export default App;
